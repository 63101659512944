import imgTV from '../../../src/images/data/sky_sport_2022.jpg';
import ios from '../../../src/images/data/app/sky-sports/skysport-ios.png';
import and from '../../../src/images/data/app/sky-sports/skysport-android.png';
import ico from '../../../src/images/data/My_Sky_Sports_logo_Retail_App_Assets_IT.png';

import logo from '../../../src/images-static/logo-colored.jpg';

const app = {
    data : {
        wp: {
            app: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                links :
                    {
                    prev : '/apps/sky-news', 
                    next : '/apps/sky-kids'
                },
                options : [
                    {
                        id: 'q-app',
                        ico : ico,
                        text : '<h1>Sky Sports</h1><p>Get the biggest and latest sports news stories and live blogs, updates on live goals as they go in and all the video highlights of the Premier League, Championship and more. Dedicated sports sections for Football, Formula 1, Boxing, Cricket, Golf, Rugby Union, Rugby League, Tennis, NFL, NBA, Darts, GAA, Netball, Racing and Other Sports.</p>',
                        qr : [
                            {
                                img : ios,
                                code : 'ios',
                                link : '',
                                label : 'iOS'
                            },
                            {
                                img : and,
                                code : 'and',
                                link : '',
                                label : 'Android'
                            },
                            
                        ],
                        image: {
                            mediaItemUrl:imgTV
                        },
                    },
                ]
            }
        },

    }
}

export default app;